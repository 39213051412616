/**
 * Get a random integer clamped to a minimum and maxiumum value
 * @param min the minimum value to return
 * @param max the maximum value to return
 * @returns the random integer
 */
export const randomInt = (min: number, max: number): number => {
  if (min > max) {
    throw new RangeError('min may not be greater than max');
  }
  return Math.floor(min + Math.random() * (max - min + 1));
};

/**
 * Clamps an number to a minimum and maximum value. If the integer is above or below the clamped
 * values the function will return the clamped min/max respectively
 * @param min the minimum value
 * @param max the maximum value
 * @returns the clamped value
 */
export const clamp = (value: number, min: number, max: number): number => {
  if (min > max) {
    throw new RangeError('min may not be greater than max');
  }
  return Math.max(Math.min(value, max), min);
};
